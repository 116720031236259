import { headPageMixin, headCatalogMixin, headCatalogOverviewMixin, headProductMixin, whereToBuyPageMixin, whereToBuyCompanyPageMixin } from '~/mixins/seo';

export const rememberExpiresMixin = {
  mounted() {
    let rememberExpires = this.$auth.$storage.getCookie('remember_expires');
    if (rememberExpires) {
      const cookiePrefix = this.$auth.options.localStorage.prefix;
      for (const [key, value] of Object.entries(this.$auth.$storage.getCookies()).filter(x => x[0].startsWith(cookiePrefix))) {
        this.$auth.$storage.setCookie(key.replace(cookiePrefix, ''), value, { expires: rememberExpires });
      }
    }
  },
}

export const mixinDetectingMobile = {
  methods: {
    isMobile: function () {
      return this.$device.isMobile
    }
  },
}

export {
  headPageMixin,
  headCatalogMixin,
  headCatalogOverviewMixin,
  headProductMixin,
  whereToBuyPageMixin,
  whereToBuyCompanyPageMixin,
}
